@import "../_variables.scss";

.searchbar__container {
	.searchbar__input-container {
		.searchbar__icon-container {
			background-color: white;
			border: 1px solid #495057;
			border-radius: 5px 0 0 5px;
			width: 2.5rem;
			.searchbar__icon {
				position: relative;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.searchbar__input {
			border-color: #495057;
		}
		.searchbar__input:focus {
			outline: none;
			box-shadow: none;
			border-color: #495057;
		}
	}
	.searchbar__results {
		background: white;
		position: absolute;
		top: 3rem;
		z-index: 20;
		padding: 3px;
		max-height: 8rem;
		overflow: auto;
		color: #495057;
		border: 1px solid $border-color;
		border-radius: $border-radius;
		.searchbar__record {
			color: #999;
			.searchbar__record-path {
				font-size: 0.9rem;
			}
		}
		.searchbar__record-active {
			cursor: pointer;
			color: black;
			.searchbar__record-path {
				font-size: 0.9rem;
			}
		}
	}
	.searchbar__results-hidden {
		display: none;
	}
}

@media (max-width: 680px) {
	.searchbar__container {
		width: calc(100% - 3rem);
	}
	.searchbar__results {
		width: calc(100% - 5rem);
	}
}

@media (min-width: 681px) {
	.searchbar__container {
		width: calc(100% - 4rem);
		.searchbar__input-container {
			margin-left: 13rem;
			width: calc(100% - 23.5rem);
		}
	}
	.searchbar__results {
		margin-left: 15.5rem;
		width: calc(100% - 32rem);
	}
}
