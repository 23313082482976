@keyframes spin-animation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

#EssSpinner {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.spinner-container {
		position: relative;
		.spinner {
			position: absolute;
		}
		.spinner-ring {
			animation-name: spin-animation;
			animation-duration: 1.5s;
			animation-timing-function: cubic-bezier(0.75, 0, 0.5, 1);
			animation-iteration-count: infinite;
			filter: invert(19%) sepia(43%) saturate(446%) hue-rotate(163deg) brightness(87%) contrast(94%);
		}
		.spinner-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			filter: invert(19%) sepia(43%) saturate(446%) hue-rotate(163deg) brightness(87%) contrast(94%);
		}
		.spinner-icon-light {
			filter: invert(1);
		}
		.spinner-icon-dark {
		}
	}
}

@media (max-width: 679px) {
	#EssSpinner {
		.spinner-icon {
			width: 5rem;
			height: 5rem;
		}
		.spinner {
			width: 11rem;
			height: 11rem;
		}
		.spinner-container {
			width: 11rem;
			height: 11rem;
		}
	}
}

@media (min-width: 680px) {
	#EssSpinner {
		.spinner-icon {
			width: 8rem;
			height: 8rem;
		}
		.spinner {
			width: 15rem;
			height: 15rem;
		}
		.spinner-container {
			width: 15rem;
			height: 15rem;
		}
	}
}
