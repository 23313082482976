#EssScrollLight {
	padding-right: 5px;
}

#EssScrollLight::-webkit-scrollbar {
	width: 3px;
	background-color: #00000000;
	border-radius: 0;
}

#EssScrollLight::-webkit-scrollbar-thumb {
	background-color: #bbb;
}

#EssScrollLight::-webkit-scrollbar-track {
	opacity: 0;
}
