.test-container {
	width: 100%;
	height: 100%;
	background: white;
	display: flex;
	flex-wrap: wrap;
	justify-content: stretch;
	min-height: 300px;
}
.column-1 {
	min-width: 300px;
	overflow: auto;
}
.column-2 {
	width: 300px;
}
.test-1 {
	background-color: red;
	width: 100%;
	height: 100%;
}
.test-2 {
	background-color: blue;
	width: 100%;
	height: 100%;
}
.test-3 {
	background-color: yellow;
	width: 100%;
	height: 4rem;
}
.test-4 {
	background-color: green;
	width: 100%;
	height: 100%;
}
.test-5 {
	background-color: purple;
	width: 100%;
	height: 100%;
}
