@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Open+Sans+Condensed:wght@300&display=swap");
@import "../../../node_modules/devextreme/dist/css/dx.light.css";
@import "../_variables.scss";
@import "./DevExtereme.scss";
@import "mapbox-gl/dist/mapbox-gl.css";

html,
body,
#root {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	background-color: white;
}
html::-webkit-scrollbar {
	width: 5px;
	background-color: #00000000;
	border-radius: 0;
}
html::-webkit-scrollbar-thumb {
	background-color: #bbb;
}
html::-webkit-scrollbar-track {
	opacity: 0;
}

.main-app {
	width: 100%;
	background: url("../../resources/bg3.webp");
	background-attachment: fixed;
}

.main-app-full {
	width: 100%;
	min-height: 100%;
	background: url("../../resources/bg3.webp");
	background-attachment: fixed;
}

.btn:focus {
	box-shadow: none;
}

.main-app-anonymous {
	width: 100%;
	height: 100%;
	background: url("../../resources/bg1.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.status-popup {
	z-index: 3000;
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	padding: 7px;
	border-radius: 5px;
}

.btn-secondary {
	background: #444 !important;
	border: 1px solid #444 !important;
}

.form-submit-button {
	width: 100%;
}
button {
	position: relative;
	.add-button-icon {
		width: 2rem !important;
		height: 2rem !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.move-left {
	width: calc(1.5em + 0.75rem + 2px);
	height: calc(1.5em + 0.75rem + 2px);
}

@media (min-width: 641px) {
	.main-app {
		height: 100%;
	}
}

@media (max-width: 640px) {
	.main-app {
		min-height: 100%;
	}
}
