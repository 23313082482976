@import "../_variables.scss";

.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell {
	background-color: #f0f0f0;
}

.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
	padding: 7px;
}
.dx-datagrid-form-buttons-container {
	float: left;
}

.alertRow {
	background-color: #fd7d78;
}

.dxDataGridContainer {
	width: 100%;
	height: 100%;
	background: white;
	padding: 10px;
	display: flex;
	flex-direction: column;
	border-radius: $border-radius;
	border: 1px solid $border-color;
}

.dxDataGridNoBorder {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
