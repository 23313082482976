.user-card__container {
	width: 15rem;
	height: auto;
	display: grid;
	margin-top: 1rem;
	grid-template-columns: 30% 70%;
	justify-items: center;
	align-items: center;
	.user-card__content {
		color: #ccc;
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: column;
		justify-content: center;
		transition: 0.5s;
		h6 {
			margin-bottom: 0;
		}
	}
}

.user-card__container:hover {
	.user-card__content {
		color: white;
		cursor: pointer;
		transition: 0.5s;
	}
}

.user-card__icon {
	width: 2.5rem !important;
	height: 2.5rem !important;
	filter: invert(100%);
	cursor: pointer;
}

.user-card__menu {
	display: grid;
	grid-auto-rows: 3rem;
	grid-gap: 10px;
}

.avatar__container {
	display: grid;
	grid-auto-rows: 3rem;
	grid-template-columns: 20% 20% 20% 20% 20%;
	justify-items: center;
	align-items: center;
}
