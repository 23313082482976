.ess-nav-link__wrapper {
	height: 2rem;
	margin: 10px;
	font-family: "Open Sans Condensed", sans-serif;
	a {
		width: 100%;
		height: 100%;
		padding: 0;
		.ess-nav-link__container {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			.ess-nav-link__icon-container {
				display: flex;
				align-items: left;
				justify-content: left;
				float: left;
				width: 2rem;
				position: relative;

				.ess-nav-link__icon {
					height: 2rem;
					width: 2rem;
					transition: 0.5s;
					filter: invert(100%) brightness(70%);
				}
				.icon-active {
					transition: 0.5s;
					filter: invert(100%);
				}
			}

			.ess-nav-link__text-container {
				margin-left: 0.5rem;
				float: left;
				max-width: 11rem;
				display: flex;
				align-items: center;
				height: 100%;
				.notification {
					border-radius: 100%;
					background-color: white;
					width: 24px;
					height: 24px;
					font-size: 12px;
					font-family: "Open Sans", sans-serif;
					margin-left: 0.5rem;
					color: black;
					text-align: center;
					position: relative;
					span {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
				.ess-nav-link__text {
					color: #ccc;
					transition: 0.5s;
					font-size: 1.25rem;
				}
				.text-active {
					color: white;
					transition: 0.5s;
				}
			}
		}

		.ess-nav-link__container:hover {
			width: 100%;
			height: 100%;
			.ess-nav-link__icon {
				height: 2rem;
				width: 2rem;
				transition: 0.5s;
				filter: invert(100%);
			}

			.ess-nav-link__text {
				color: white;
				transition: 0.5s;
				font-size: 1.25rem;
			}
		}
	}
}
