@import "../_variables.scss";

#EssTabs {
	height: 100%;
	ul {
		height: 2.6rem;
		margin-bottom: -1px;
		li {
			a {
				background-color: #ddd;
				border: 1px solid $border-color;
				color: black;
				height: 2.1rem;
				margin-top: 0.5rem;
				span {
					padding-bottom: 1rem;
				}
			}
			.active {
				background: #808080;
				border-bottom: none !important;
				font-weight: bold;
				height: 2.6rem;
				margin-top: 0;
			}
		}
	}
	.tab-content {
		color: white;
		border: 1px solid $border-color;
		height: calc(100% - 2.6rem);
		overflow: auto;
		border-radius: $border-radius;
		border-top-left-radius: 0;
		.tab-pane {
			height: 100%;
			border-radius: $border-radius;
			border-top-left-radius: 0;
		}
	}
}

#EssLinkTabs {
	height: 100%;
	position: relative;
	.custom-nav-tabs {
		display: flex;
		height: 2.6rem;
		margin-bottom: -1px;
		align-items: flex-end;
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		.custom-nav-item {
			background-color: #eee;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 15px;
			border: 1px solid $border-color;
			white-space: nowrap;
			border-top-left-radius: $border-radius;
			border-top-right-radius: $border-radius;
			height: 2rem;
		}
		.tabActive {
			background-color: white;
			height: 2.5rem;
			border-bottom: 0;
			font-weight: bold;
		}
	}
	.custom-nav-tabs::-webkit-scrollbar,
	.custom-nav-tabs::-webkit-scrollbar-thumb,
	.custom-nav-tabs::-webkit-scrollbar-track {
		display: none;
	}
	.active {
		width: 100%;
	}
	.tab-content {
		width: 100%;
		height: calc(100% - 2.6rem);
		background: white;
		border: 1px solid #bbb;
		padding: 10px;
		display: flex;
		overflow: auto;
		flex-direction: row;
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		.tab-pane {
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}
}
