@import "../_variables.scss";

.custom-modal {
	backdrop-filter: blur(5px) brightness(50%);
}

.custom-modal-dialog {
	border-radius: $border-radius !important;
	margin-top: 4rem;
}

.custom-modal-content {
	color: black;
	border-radius: $border-radius !important;
	background: linear-gradient(#ccc, #fff) !important;
	border: 1px solid $border-color !important;
}

.custom-modal-button {
	width: 2rem;
	height: 2rem;
	position: relative;
}

.close-icon {
	max-width: 2rem;
	max-height: 2rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
