@import "../_variables.scss";

#EssTable {
	color: $text-color;
	background: $bg-color;
	border: 1px solid $border-color;
	border-radius: $border-radius;
	overflow: auto;
	height: 100%;
	div {
		background-color: #808080;
		display: flex;
		flex-direction: row-reverse;
		height: 2rem;
		.config-btn {
			width: 2rem;
			height: 2rem;
			position: fixed;
			z-index: 3;
			background: none !important;
			border: none !important;
			.config-btn-icon {
				width: 1.25rem;
				height: 1.25rem;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				color: black;
			}
		}
	}
	table {
		border-collapse: separate;
		border-spacing: 0;
		border-bottom: 1px solid $border-color;
		width: 100%;
		thead {
			height: 30px;

			th {
				border-bottom: 1px solid $border-color;
				padding: 5px;
				word-wrap: normal;
				word-break: keep-all;
				white-space: nowrap;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
		}
		#Sortable {
			tr {
				th {
					cursor: pointer;
					padding-left: 30px;
					svg {
						margin-left: 5px;
					}
				}
				.not-sorted {
					cursor: default !important;
					padding-left: 10px !important;
				}
			}
		}
		tbody {
			tr {
				td {
					height: 50px;
					padding: 10px;
					text-align: center;
				}
			}
			.no-limit {
				td {
					height: auto;
				}
			}
			.selected-tr {
				background-color: $selected-bg;
			}
			.sub-table-active {
				td {
					padding: 0;
					table {
						width: 100%;
						thead {
							th {
								border-bottom: 0;
							}
						}
					}
				}
			}
			.sub-table-hidden {
				display: none;
			}
		}
	}
}

.add-button-list {
	table {
		tbody {
			tr:last-child {
				td {
					padding: 0 !important;
					button {
						width: 100%;
						height: 100%;
						border-radius: 0;
						border-top: 1px solid $border-color;
					}
				}
			}
		}
	}
}

.fixed-width {
	table {
		table-layout: fixed;
	}
}

.border-none {
	border: 0 !important;
	border-radius: 0 !important;
}

@media (max-width: 549px) {
	#EssTable {
		table {
			overflow: auto;
			thead {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}
			tbody {
				display: block;
				tr {
					display: block;
					td {
						display: flex;
						align-items: center;
						justify-content: center;
						border: none;
						position: relative;
						padding-left: 50%;
						white-space: normal;
						text-align: center;
					}
					td::before {
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);
						left: 5px;
						width: 45%;
						padding-right: 10px;
						white-space: nowrap;
						font-weight: bold;
						text-align: left;
						content: attr(data-title);
					}

					td:nth-child(1) {
						border-top: 1px solid $border-color;
						background-color: $bg-color;
					}
				}
				.sub-table-active {
					td:nth-child(1) {
						border-top: 1px solid $border-color;
						background-color: $bg-color;
					}
					td {
						padding-left: 0%;
						height: auto;
						table {
							border-collapse: separate;
							border-spacing: 0;
							tr {
								background-color: #666;
								td {
									display: flex;
									align-items: center;
									border: none;
									position: relative;
									padding-left: 50%;
									white-space: normal;
									text-align: left;
									height: 50px;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 550px) {
	#EssTable {
		table {
			thead {
				font-size: 18px;
				tr {
					th {
						color: $text-color;
						background-color: #808080;
						text-align: center;
					}
				}
			}
			tbody {
				tr {
					td {
						text-align: center;
					}
				}
				.sub-table-active {
					td {
						table {
							border-bottom: 1px solid $border-color;
						}
					}
				}
				.selected-tr {
					td {
						border-top: 1px solid $border-color;
					}
				}
			}
		}
	}
	.sticky-head {
		tr {
			th {
				position: sticky;
				top: 0;
				z-index: 2;
			}
		}
	}
}

#EssTable::-webkit-scrollbar {
	width: $scroll-width;
	background-color: #000;
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
}

#EssTable::-webkit-scrollbar-thumb {
	border-radius: $border-radius;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: $scroll-color;
}

#EssTable::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
	background-color: #000;
}
