@import "../_variables.scss";

.customer-details__container {
	height: 100%;
	width: 100%;
	.customer-info__container {
		font-size: 14px;
	}
}

.portal-user__container {
	width: 100%;
	height: 100%;
	background-color: white;
	padding: 10px;
	border: 1px solid $border-color;
	border-radius: $border-radius;
}
