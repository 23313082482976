@import "../_variables.scss";

.navbar {
	background: #090919;
	color: white;
	font-size: 1.25rem;
	display: flex;
	width: 100%;
	position: sticky;
	z-index: 100;
	height: 3.5rem;
	top: 0;
}

.location {
	height: 2rem;
	border-radius: $border-radius;
	background: #ffffff80;
	border: 1px solid $border-color;
	display: flex;
	font-size: 20px;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	margin-bottom: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis ellipsis;
}

.layout {
	width: 100%;
	height: 100%;
	font-family: "Open Sans", sans-serif;
}

.side-nav {
	background: #090919;
	position: fixed;
	top: calc(3.5rem - 5px);
	height: 100%;
	width: 16rem;
}

.page-content {
	width: 100%;
	height: calc(100% - 2.5rem);
}

.error__container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: white;
	padding: 5px;
	border: 1px solid $border-color;
	color: black;
	border-radius: $border-radius;
	p:first-child {
		font-size: 8rem;
	}
	p:last-child {
		font-size: 2rem;
	}
}

.success-container {
	.success-icon {
		width: 7rem;
		height: 7rem;
	}
}

@media (max-width: 679px) {
	.layout {
		display: flex;
		flex-direction: column;
	}

	.navbar {
		background-color: #090919;
		color: white;
		font-size: 1.25rem;
		height: 3.5rem;
		position: sticky;
		top: 0;
		left: 0;
	}

	.content {
		margin: 20px;
		width: calc(100% - 40px);
		height: calc(100% - 40px);
	}

	.side-nav-shown {
		transition: 0.5s;
		left: 0px;
		z-index: 10;
		width: 16rem;
		height: 100%;
	}

	.side-nav-hidden {
		left: -100%;
		transition: 0.5s;
		z-index: 10;
	}

	.form-group {
		margin-bottom: 0.5rem !important;
	}
}
@media (min-width: 680px) {
	#toggle-button {
		width: 2rem;
		height: 2rem;
		opacity: 0;
		cursor: default;
	}
	.layout {
		display: grid;
		grid-template-columns: 16rem calc(100% - 16rem);
		grid-template-rows: 3.5rem calc(100% - 3.5rem);
	}

	.navbar {
		grid-row: 1;
		grid-column: 1/3;
		justify-content: space-between;
	}

	.content {
		grid-row: 2;
		grid-column: 2;
		margin: 20px;
		width: calc(100% - 40px);
		height: calc(100% - 40px);
	}

	.side-nav {
		grid-row: 2;
		grid-column: 1;
		width: 16rem;
		height: 100%;
	}
}
