@import "../variables.scss";

#login__wrapper {
	font-family: "Open Sans", sans-serif;
	width: clamp(20rem, 20vw, 80%);
	min-height: 35rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #000000a0;
	border: 1px solid $border-color;
	border-radius: $border-radius;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	padding: 30px;
	.login__container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.login__forgot-text {
			cursor: pointer;
			color: #46a2f8;
		}

		.login__forgot-text:hover {
			color: #ffffff;
		}

		.login__message {
			color: red;
			text-align: center;
			font-size: 1.25rem;
		}
		.login__logo-container {
			height: 170px;
			margin-left: auto;
			margin-right: auto;
			width: 170px;
			margin-bottom: 2.5rem;

			.login__logo {
				max-height: 170px;
				max-width: 170px;
				height: 100%;
				width: 100%;
			}
		}

		.login__icon-container {
			width: 40px;
			height: 40px;
			background-color: white;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid $border-color;
			border-top-right-radius: 0.25rem;
			border-bottom-right-radius: 0.25rem;
			.login__icon {
				width: 1.5rem;
				height: 1.5rem;
			}
		}

		.login__input {
			width: 100%;
			height: 40px;
		}
		.login__button {
			width: 100%;
		}
		.login__input-group {
			position: relative;
			display: grid;
			grid-template-columns: calc(100% - 40px) 40px;
			width: 100%;
			border-radius: 0.25rem;
		}
		.form-control:focus {
			box-shadow: none;
		}
	}
}
